import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, EventEmitter, SimpleChanges, Output, Input } from '@angular/core';
import { Constant } from '../../../../../../common/constant';
import { fadeInAnimation, fadeInOut } from '../../../../../../common/service/routerTransition';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvestorService } from 'src/app/main/investor/services/investor.service';
import { AppQueries } from 'src/app/state';
import { Subscription } from 'rxjs';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'info-payment-reward',
  templateUrl: './info-payment-reward.html',
  styleUrls: ['./info-payment-reward.scss'],
  animations: [fadeInAnimation, fadeInOut],
  host: { '[@fadeInAnimation]': '' }
})

export class InforPaymentReward implements OnInit, OnDestroy {
  @Input('transaction') transaction;
  @Input('info') info;
  @Input('isContributeGoal') isContributeGoal;
  public Constant = Constant;
  isCopied1: boolean = false;
  isCopied2: boolean = false;
  isCopied3: boolean = false;
  isCopied4: boolean = false;
  isCopied5: boolean = false;
  inputDisabled: boolean = false;
  isDevice: any = false;
  isTransferNormal: any = false;
  selectedBank: any;
  banks: any = [];
  subscriptions: Subscription[] = [];
  constructor(
    private eventManager: EventManagerService,
    private appQueries: AppQueries,
    public activeModal: NgbActiveModal,
    public investorService: InvestorService,
  ) {
  }
  ngOnInit() {
    this.selectedBank = {};
    this.appQueries.isDevice$.subscribe((isDevice) => {
      this.isDevice = isDevice;
    })
    this.info = this.info || {};
    this.transaction = this.transaction || {};
    this.inputDisabled = true;
    this.info.shortNameBank = this.info.shortNameBank || '';
    if (this.info.shortNameBank.includes('HSBC') || this.info.shortNameBank.includes('Standard Chartered')) {
      this.isTransferNormal = true;
    } else {
      this.isTransferNormal = false;
    }
    // this.info.qrPaymentUrl = undefined;

    $(document).ready(function () {
      $('input[name="intervaltype"]').click(function () {
        $(this).tab('show');
        $(this).removeClass('active');
      });
    });
  }
  ngOnDestroy(): void {
    this.eventManager.destroys(this.subscriptions);
  }
  openLink() {
    window.open(this.selectedBank.link, '_blank');
  }
  confirm() {
    this.eventManager.broadcast({
      name: 'open-confirm-payment',
      content: {
        transaction: this.transaction,
      }
    })
  }
  dismiss() {
    this.activeModal.dismiss();
  }
  refresh() {
    this.investorService.getDetailOrder(this.info.code)
      .subscribe((res: any) => {
        this.info.qrPaymentUrl = res.data.qrPaymentUrl;
      })
  }
  download() {
    window.open(this.info.qrPaymentUrl, '_blank');
  }
  copy(isCopied) {
    this.isCopied1 = false;
    this.isCopied2 = false;
    this.isCopied3 = false;
    this.isCopied4 = false;
    this.isCopied5 = false;
    this[isCopied] = true;
  }

}