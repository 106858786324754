<div class="modal-content">
  <div class="modal-body">
    <div class="text-center">
      <div class="col-md-12 d-flex flex-column text-center align-items-center">
        <img [src]="iconImage" class="image-error">
        <h3 class="popup-title" *ngIf="title">{{title | translate}}</h3>
        <h4 class="popup-message">{{message | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12">
        <a class="btn btn-ok" (click)="dismiss()">{{'Đóng' | translate}}</a>
      </div>
    </div>
  </div>
</div>