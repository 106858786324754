<div class="modal-contents modal-sm">
  <div class="modal-header form-title__top md-tt__top">
    <button type="button" class="close" (click)="dismiss()" title="{{'Đóng' | translate}}"><img
        src="{{Constant.icon_url_close_modal}}"></button>
    <div class="form-title__modal md-tt__top">
      <label class="form-title__header " style="text-transform: uppercase">{{'Thông tin chuyển khoản' |
        translate}}</label>
    </div>
  </div>
  <div class="modal-body horizon-mobile">
    <info-payment #infopayment id="infopayment" [paymentInfo]="paymentInfo" [transaction]="transaction"></info-payment>
  </div>
  <div class="modal-footer">
    <label *ngIf="paymentInfo?.status === Constant.ORDER_INIT && isNormal" for="" class="down note-payment">
      {{'Chuyển khoản xong, vui lòng' | translate}} [{{(isNormal ? 'Xác nhận đã thanh toán' : 'Xác nhận thanh toán') | translate}}] {{'để hoàn tất đặt lệnh.' | translate}}
    </label>
    <button *ngIf="paymentInfo?.status === Constant.ORDER_INIT" class="btn btn--blue btn--fix"
      (click)="confirm()" prevent-multi-clicks>
      {{(isNormal ? 'Xác nhận đã thanh toán' : 'Xác nhận thanh toán') | translate}}
    </button>
  </div>
</div>