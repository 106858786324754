import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {FilterPipe} from "./service/filterPipe";
import {FileUploadModule} from "ng2-file-upload";
import {Safe} from "./service/keep-html.pipe";
import { FooterComponent, NavbarComponent, } from './layouts';
import { TransformMoneyPipe } from "./pipe/transformMoney.pipe";
import { TransformCustomDatePipe } from "./pipe/transformCustomDate.pipe";
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { NewPagingComponent } from "./components/new-paging/new-paging.component";
import { UploadingIcon } from "./components/uploading-icon/uploading-icon.component";
import { UserAuthorityDirective } from "./directive/user-authority.directive";
import { ErrorTextComponent } from "./components/error-text/error-text.component";
import { NewOTPComponent } from "./components/otp/otp.component";
import { FileSizePipe } from "./service/filesize.service";
import { AutoCompleteComponent } from "./components/auto-complete/auto-complete.component";
import { FeatureOnAppPopupComponent } from "./components/feature-on-app-popup/feature-on-app-popup";
import { FeatureOnAppPageComponent } from "./components/feature-on-app-page/feature-on-app-page";
import { PaymentInfoMobile } from "../main/investor/screen/marketWatch/trading-order/payment-info-mobile/payment-info-mobile";
import { InforPaymentReward } from "../main/investor/screen/marketWatch/trading-order/info-payment-reward/info-payment-reward";
import { InforPayment } from "../main/investor/screen/marketWatch/trading-order/info-payment/info-payment";
import { ClipboardModule } from "ngx-clipboard";
import { GuidePaymentQRPopupComponent } from "./components/guide-payment-QR-popup/guide-payment-QR-popup";
import { InputNumberComponent } from "./components/input-number/input-number";
import { TabListComponent } from "./components/tab-list/tab-list.component";
import { httpTranslateLoader } from "../app.module";
import { InputDateComponent } from "./components/input-date/input-date";
import { NoDataComponent } from "./components/no-data/no-data.component";
import { FilterStartEndDateComponent } from "./components/filter-start-end-date/filter-start-end-date";
import { ChartJSLineChartVerticalComponent } from "./components/chart-js-line-chart/chart-js-line-chart.component";
import { ChartJsPieChartComponent } from "./components/chart-js-pie-chart/chart-js-pie-chart.component";
import { PreventMultiClicksDirective } from "./directive/prevent-multi-clicks.directive";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FileUploadModule,
    HttpClientModule,
    NgbModalModule,
    TranslateModule,
    ClipboardModule,
  ],
  declarations: [
    FilterPipe, Safe,
    FooterComponent,
    NavbarComponent,
    TransformCustomDatePipe,
    TransformMoneyPipe,
    FileSizePipe,
    NewPagingComponent,
    DropdownSearchComponent, AutoCompleteComponent,
    UploadingIcon,
    UserAuthorityDirective,
    ErrorTextComponent,
    NewOTPComponent,
    FeatureOnAppPopupComponent, FeatureOnAppPageComponent,
    PaymentInfoMobile, 
    InforPaymentReward, InforPayment,
    GuidePaymentQRPopupComponent,
    InputNumberComponent,
    TabListComponent,
    InputDateComponent, FilterStartEndDateComponent,
    NoDataComponent,
    ChartJSLineChartVerticalComponent, ChartJsPieChartComponent,
    PreventMultiClicksDirective, 
  ],
  exports: [
    HttpClientModule,
    TranslateModule,
    NgbModalModule,
    FileUploadModule,
    RouterModule,
    FilterPipe, 
    UploadingIcon,
    FooterComponent,
    TransformCustomDatePipe, TransformMoneyPipe, FileSizePipe, Safe,
    NewPagingComponent,
    DropdownSearchComponent, AutoCompleteComponent, NewOTPComponent,
    UserAuthorityDirective,
    ErrorTextComponent,
    FeatureOnAppPopupComponent, FeatureOnAppPageComponent,
    InforPayment, PaymentInfoMobile, GuidePaymentQRPopupComponent,
    InputNumberComponent,
    TabListComponent,
    InputDateComponent, FilterStartEndDateComponent,
    NoDataComponent,
    ChartJSLineChartVerticalComponent, ChartJsPieChartComponent,
    PreventMultiClicksDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule{ }
