<div class="modal-content">
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <div class="text-center">
      <div class="col-12 info-account text-center">
        <img [src]="iconImage" class="image-success">
        <h4 class="popup-title">{{title | translate}}</h4>
        <h6 class="popup-message" [innerHTML]="message | translate"></h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row w-100">
      <div class="text-center" [ngClass]="!titleOk ? 'col-12' : 'col-6 border-right'">
        <a class="btn common__btn btn-close" (click)="cancel()"
          >{{(titleCancel || 'Đóng') | translate}}
        </a>
      </div>
      <div class="col-6 text-center" *ngIf="titleOk">
        <a class="btn btn-ok common__btn" (click)="confirm()" *ngIf="titleOk"
          >{{(titleOk || 'Xác nhận') | translate}}
        </a>
      </div>
    </div>
  </div>
</div>