import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from "@angular/core";
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from '../../constant';
import { ShortcutService } from "../../service/shortcut.service";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'confirm-popup',
  templateUrl: "./confirm-popup.html",
  styleUrls: ["./confirm-popup.scss"],
})

export class ConfirmPopupComponent implements OnInit {
    @Input('properties') properties;
    @Input('header') header;
    @Input('title') title;
    @Input('titleHTML') titleHTML;
    @Input('buttonLabel') buttonLabel;
    @Input('buttonCancelLabel') buttonCancelLabel;
    @Input('iconImage') iconImage = Constant.icon_url_warning;
    @Input('iconSize') iconSize = 60;
    @Input() isDismissAll: boolean;
    @Output() result: EventEmitter<any> = new EventEmitter();
    Constant = Constant;
    func: any;
    cancelFunc: any;
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {
    }
    ngOnInit() {
        if (this.properties) {
            Object.keys(this.properties).forEach((key) => {
                this[key] = this.properties[key];
            });
        }
    }
    argree() {
        this.dismiss();
        if (this.func) {
            this.func();
        } else this.result.emit(true);
    }
    cancel() {
        this.dismiss();
        if (this.cancelFunc) {
            this.cancelFunc();
        } else this.result.emit(false) 
    }
    dismiss() {
        ShortcutService.removeFuncEsc();
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
