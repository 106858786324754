<div class="accordian-body" [ngClass]="isDevice ? '' : ''" [attr.id]="'info-payment-'+ info.id">
  <div class="col-12">
    <div class="row">
      <div class="col-lg-12 modal-header mb-4" *ngIf="!isDevice">
        <div class="order-info">
          <h5 style="text-transform: uppercase;">{{'thông tin thanh toán' | translate}}</h5>
          <button type="button" class="close" (click)="dismiss()" title="{{'Đóng' | translate}}"><img
              src="{{Constant.icon_url_close_modal}}"></button>
        </div>
      </div>
      <div class="col-12 modal-body" [ngClass]="!isDevice && info?.status !== Constant.ORDER_INIT ? 'fix-height' : '' "
        *ngIf="transaction">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="title-left">
                <img
                  [src]="transaction?.product?.owner?.avatarUrl !== null ? transaction?.product?.owner?.avatarUrl : ' ' "
                  [ngClass]="transaction?.product?.owner?.avatarUrl === null ? 'hidden' : ''" alt=""
                  class="product-logo">
                <div class="d-flex flex-column">
                  <label>{{transaction?.product?.shortName}}</label>
                  <span>{{transaction?.product?.owner?.shortName}}</span>
                </div>
              </div>
              <div class="title-right">
                <label class="mb-0">{{'Ngày đặt lệnh' | translate}}
                </label>
                <span>{{transaction?.extra?.buyingDate | date_vn: 'dd/MM/yyyy,
                  hh:mm'}}</span>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="block__info">
              <div class="col-lg-3 block__item">
                <label for="">{{'Số tiền thanh toán' | translate}}</label>
                <span>{{info.buyingValue}}</span>
              </div>
              <div class="col-lg-3 block__item">
                <label for="">{{'Chương trình' | translate}}</label>
                <span>{{transaction?.productProgram?.scheme?.name | translate}}</span>
              </div>
              <div class="col-lg-4 block__item">
                <label for="">{{'Sổ lệnh đóng' | translate}}</label>
                <span>{{info?.isOrderBuyIpo ? (info?.ipoEndTime | date_vn: 'dd/MM/yyyy, hh:mm') :
                  info?.closedOrderBookString}}</span>
              </div>
              <div class="col-lg-2 block__item">
                <label for="">{{'Phiên khớp lệnh' | translate}}</label>
                <span>{{info?.isOrderBuyIpo ? '_' : info?.tradingTimeString}}</span>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="col-12 p-3 modal-footer" *ngIf="!isDevice && info?.status === Constant.ORDER_INIT">
        <div class="row">
          <div class="col-12 col-lg-12 d-flex justify-content-center ">
            <!-- <button class="btn btn-close mr-4" (click)="dismiss()">{{'Đóng' | translate}}</button> -->
            <button *ngIf="info?.status === Constant.ORDER_INIT" class="btn btn--blue" (click)="confirm()">{{'Xác
              nhận thanh toán' | translate}} <br /> {{'bằng tiền thưởng' | translate}} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>