export class NumberUtil {
  static decimalAdjust(value, exp?, type?) {
    exp = exp || 2;
    type = type || 'round';
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    return Math[type](value * valueMul) / valueMul;
  }
  static revertMoneytoNumber(number, returnNull?) {
    number && (number+='');
    if (number && number.length > 0) {
      let array =number.split(',');
      number='';
      array.forEach((item) => {
        number+=item
      })
      return parseFloat(number)
    } else if (number !== 0) {
      return !returnNull ? 0 : null;
    } else {
      return 0
    }
  }
  static numberToMoney(number: any) {
    let isNegative: boolean = false;
    if (number < 0) {
      isNegative = true;
      number = number*-1;
    }
    let numberText = number +'';
    let numberPositive = numberText.split('.')[0] || '';
    let numberDecimal = numberText.split('.')[1] || '';
    let text = '';
    let position = 0;
    for (let i=numberPositive.length -1; i >= 0; i--) {
      if (position === 3) {
        text = ',' + text; 
        position = 0;
      }
      text = numberPositive.charAt(i) + text;
      position++;
    }
    if (isNegative) {
      text = '-' + text;
    }
    text = numberDecimal ? text + '.' + numberDecimal : text;
    return text;
  }
}
